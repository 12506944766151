export default [
  {
    path: '/',
    name: 'dashboard',
    component: () => import('@/views/pages/inside/Dashboard/MyDashboard.vue'),
    meta: {
      logo: 'admin',
      pageTitle: 'Dashboard',
      breadcrumb: [
        {
          text: 'Dashboard',
          active: true,
        },
      ],
      resource: 'Dashboard',
      action: 'manage',
    },
  },
  {
    path: '/order-tracker',
    name: 'order-tracker',
    component: () => import('@/views/pages/inside/B2Bpro/OrderTracker/MyOrderTracker.vue'),
    meta: {
      logo: 'b2bpro',
      pageTitle: 'Order Tracker',
      breadcrumb: [
        {
          text: 'B2BPro',
          active: false,
        },
        {
          text: 'Order Tracker',
          active: true,
        },
      ],
      resource: 'OrderTracker',
      action: 'manage',
    },
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/pages/inside/B2Bpro/Users/MyUser.vue'),
    meta: {
      logo: 'b2bpro',
      pageTitle: 'Users',
      breadcrumb: [
        {
          text: 'B2BPro',
          active: false,
        },
        {
          text: 'Users',
          active: true,
        },
      ],
      resource: 'B2BProUsers',
      action: 'manage',
    },
  },
  {
    path: '/order-details/:id',
    name: 'order-details',
    component: () => import('@/views/pages/inside/B2Bpro/OrderTracker/OrderDetails.vue'),
    meta: {
      logo: 'b2bpro',
      pageTitle: 'Order Summary',
      breadcrumb: [
        {
          text: 'B2BPro',
          active: false,
        },
        {
          text: 'Order Tracker',
          active: false,
          to: { name: 'order-tracker' },
        },
        {
          text: '',
          active: true,
        },
      ],
      resource: 'OrderTracker',
      action: 'read',
    },
  },
  {
    path: '/user-details/:id',
    name: 'user-details',
    component: () => import('@/views/pages/inside/B2Bpro/Users/UserDetails.vue'),
    meta: {
      logo: 'b2bpro',
      pageTitle: 'User Details',
      breadcrumb: [
        {
          text: 'B2BPro',
          active: false,
        },
        {
          text: 'Users',
          active: false,
          to: { name: 'users' },
        },
        {
          text: '',
          active: true,
        },
      ],
      resource: 'B2BProUsers',
      action: 'manage',
    },
  },
  {
    path: '/markets',
    name: 'markets',
    component: () => import('@/views/pages/inside/B2Bpro/Markets/MyMarkets.vue'),
    meta: {
      logo: 'b2bpro',
      pageTitle: 'Markets',
      breadcrumb: [
        {
          text: 'B2BPro',
          active: false,
        },
        {
          text: 'Markets',
          active: true,
        },
      ],
      resource: 'Markets',
      action: 'manage',
    },
  },
  {
    path: '/markets/:marketId/branches',
    name: 'market-branches',
    component: () => import('@/views/pages/inside/B2Bpro/Markets/MarketBranches.vue'),
    props: true,
    meta: {
      logo: 'b2bpro',
      pageTitle: 'Branches',
      breadcrumb: [
        {
          text: 'B2BPro',
          active: false,
        },
        {
          text: 'Markets',
          active: false,
          to: { name: 'markets' },
        },
        {
          text: 'Branches',
          active: true,
        },
      ],
      resource: 'Markets',
      action: 'manage',
    },
  },
  {
    path: '/vendors',
    name: 'vendors',
    component: () => import('@/views/pages/inside/B2Bpro/Vendor/MyVendor.vue'),
    meta: {
      logo: 'b2bpro',
      pageTitle: 'Vendor',
      breadcrumb: [
        {
          text: 'B2BPro',
          active: false,
        },
        {
          text: 'Vendors',
          active: true,
        },
      ],
      resource: 'Vendors',
      action: 'manage',
    },
  },
  {
    path: '/inquiries',
    name: 'inquiries',
    component: () => import('@/views/pages/inside/B2Bpro/Inquiries/Inquiries.vue'),
    meta: {
      logo: 'b2bpro',
      pageTitle: 'Inquiries',
      breadcrumb: [
        {
          text: 'B2BPro',
          active: false,
        },
        {
          text: 'Inquiries',
          active: true,
        },
      ],
      resource: 'Inquiries',
      action: 'manage',
    },
  },
  {
    path: '/assigned-tracker',
    name: 'assigned-tracker',
    component: () =>
      import('@/views/pages/inside/Smarttruck/AssignedTracker/MyAssignedTracker.vue'),
    meta: {
      logo: 'smarttruck',
      pageTitle: 'Assigned Tracker',
      breadcrumb: [
        {
          text: 'SmartTruck',
          active: false,
        },
        {
          text: 'Assigned Tracker',
          active: true,
        },
      ],
      resource: 'AssignedTracker',
      action: 'manage',
    },
  },
  {
    path: '/delivery-summary/:deliveryId',
    name: 'delivery-summary',
    component: () => import('@/views/pages/inside/Smarttruck/AssignedTracker/DeliverySummary.vue'),
    meta: {
      logo: 'admin',
      pageTitle: 'Delivery Summary',
      breadcrumb: [
        {
          text: 'Transactions',
          active: false,
        },
        {
          text: 'Delivery',
          active: true,
        },
      ],
      resource: 'Auth',
      action: 'manage',
    },
  },
  {
    path: '/drivers',
    name: 'drivers',
    component: () => import('@/views/pages/inside/Smarttruck/Drivers/MyDrivers.vue'),
    meta: {
      logo: 'smarttruck',
      pageTitle: 'Drivers',
      breadcrumb: [
        {
          text: 'SmartTruck',
          active: false,
        },
        {
          text: 'Drivers',
          active: true,
        },
      ],
      resource: 'Drivers',
      action: 'manage',
    },
  },
  {
    path: '/driver/:id',
    name: 'driver',
    component: () => import('@/views/pages/inside/Smarttruck/Drivers/DriverDetails.vue'),
    meta: {
      logo: 'smarttruck',
      pageTitle: 'Driver Details',
      breadcrumb: [
        {
          text: 'SmartTruck',
          active: false,
        },
        {
          text: 'Drivers',
          active: false,
          to: { name: 'drivers' },
        },
        {
          text: '',
          active: true,
        },
      ],
      resource: 'Auth',
      action: 'manage',
    },
  },
  {
    path: '/cancellation-requests',
    name: 'cancellation-requests',
    component: () => import('@/views/pages/inside/Smarttruck/CancellationRequests.vue'),
    meta: {
      logo: 'smarttruck',
      pageTitle: 'Cancel Requests',
      breadcrumb: [
        {
          text: 'SmartTruck',
          active: false,
        },
        {
          text: 'Cancellation Requests',
          active: true,
        },
      ],
      resource: 'CancellationRequests',
      action: 'manage',
    },
  },
  {
    path: '/transactions',
    name: 'transactions',
    component: () => import('@/views/pages/inside/Transaction/MyTransaction.vue'),
    meta: {
      logo: 'admin',
      pageTitle: 'Transactions',
      breadcrumb: [
        {
          text: 'Transactions',
          active: true,
        },
      ],
      resource: 'Transactions',
      action: 'manage',
    },
  },

  //= =========Phase 2==========//
  {
    path: '/coverage',
    name: 'coverage',
    component: () => import('@/views/pages/inside/Coverage/MyCoverage.vue'),
    meta: {
      logo: 'admin',
      pageTitle: 'Coverage',
      breadcrumb: [
        {
          text: 'Coverage',
          active: true,
        },
      ],
      resource: 'Coverage',
      action: 'manage',
    },
  },
  {
    path: '/tracking',
    name: 'tracking',
    component: () => import('@/views/pages/inside/Tracking/MyTracking.vue'),
    meta: {
      logo: 'admin',
      pageTitle: 'Tracking',
      breadcrumb: [
        {
          text: 'Tracking',
          active: true,
        },
      ],
      resource: 'Tracking',
      action: 'manage',
    },
  },
  {
    path: '/admin-access',
    name: 'admin-access',
    component: () => import('@/views/pages/inside/AdminAccess/MyAdminAccess.vue'),
    meta: {
      logo: 'admin',
      pageTitle: 'Admin Access',
      breadcrumb: [
        {
          text: 'Admin Access',
          active: true,
        },
      ],
      resource: 'AdminAccess',
      action: 'manage',
    },
  },
  {
    path: '/role-access',
    name: 'role-access',
    component: () => import('@/views/pages/inside/AdminAccess/RoleAccess.vue'),
    meta: {
      logo: 'admin',
      pageTitle: 'Role Access',
      breadcrumb: [
        {
          text: 'Role Access',
          active: true,
        },
      ],
      resource: 'RoleAccess',
      action: 'manage',
    },
  },
  {
    path: '/delivery-tracking/:id',
    name: 'delivery-tracking',
    component: () => import('@/views/pages/inside/Tracking/DeliveryTracking.vue'),
    meta: {
      logo: 'admin',
      pageTitle: 'Delivery Tracking',
      breadcrumb: [
        {
          text: 'Tracking',
          active: false,
        },
        {
          text: 'Delivery Tracking',
          active: true,
        },
      ],
      resource: 'Tracking',
      action: 'manage',
    },
  },
  {
    path: '/order-tracking/:id',
    name: 'order-tracking',
    component: () => import('@/views/pages/inside/Tracking/OrderTracking.vue'),
    meta: {
      logo: 'b2bpro',
      pageTitle: 'Order Tracking',
      breadcrumb: [
        {
          text: 'Order Details',
          active: true,
        },
      ],
      resource: 'Tracking',
      action: 'manage',
    },
  },
  {
    path: '/price-algorithm',
    name: 'price-algorithm',
    component: () => import('@/views/pages/inside/PriceChecker/MyPriceChecker.vue'),
    meta: {
      logo: 'admin',
      pageTitle: 'Price Algorithm',
      breadcrumb: [
        {
          text: 'Price Algorithm',
          active: true,
        },
      ],
      resource: 'PriceAlgorithm',
      action: 'manage',
    },
  },
  {
    path: '/price-algorithm-logs',
    name: 'price-algorithm-logs',
    component: () => import('@/views/pages/inside/PriceChecker/PriceAlgorithmLogs.vue'),
    meta: {
      logo: 'admin',
      pageTitle: 'Price Logs',
      breadcrumb: [
        {
          text: 'Price Logs',
          active: true,
        },
      ],
      resource: 'PriceAlgorithmLogs',
      action: 'manage',
    },
  },
  {
    path: '/reports',
    name: 'reports',
    component: () => import('@/views/pages/inside/Reports/MyReports.vue'),
    meta: {
      logo: 'admin',
      pageTitle: 'Reports',
      breadcrumb: [
        {
          text: 'Reports',
          active: true,
        },
      ],
      resource: 'Reports',
      action: 'manage',
    },
  },
  {
    path: '/vouchers-generator',
    name: 'vouchers-generator',
    component: () => import('@/views/pages/inside/VouchersGenerator.vue'),
    meta: {
      logo: 'admin',
      pageTitle: 'Vouchers Generator',
      breadcrumb: [
        {
          text: 'Vouchers Generator',
          active: true,
        },
      ],
      resource: 'VouchersGenerator',
      action: 'manage',
    },
  },
  {
    path: '/voucher-logs',
    name: 'voucher-logs',
    component: () => import('@/views/pages/inside/VouchersLogs.vue'),
    meta: {
      logo: 'admin',
      pageTitle: 'Vouchers Logs',
      breadcrumb: [
        {
          text: 'Vouchers Logs',
          active: true,
        },
      ],
      resource: 'VoucherLogs',
      action: 'manage',
    },
  },
  {
    path: '/order-summary/:id',
    name: 'order-summary',
    component: () => import('@/views/pages/inside/DeliverySummary.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'manage',
    },
  },
  {
    path: '/orders-analytics',
    name: 'orders-analytics',
    component: () => import('@/views/pages/inside/Analytics/OrdersAnalytics.vue'),
    meta: {
      logo: 'admin',
      pageTitle: 'Orders Analytics',
      breadcrumb: [
        {
          text: 'Orders Analytics',
          active: true,
        },
      ],
      resource: 'OrdersAnalytics',
      action: 'manage',
    },
  },
  {
    path: '/shipments-analytics',
    name: 'shipment-analytics',
    component: () => import('@/views/pages/inside/Analytics/ShipmentsAnalytics.vue'),
    meta: {
      logo: 'admin',
      pageTitle: 'Shipments Analytics',
      breadcrumb: [
        {
          text: 'Shipments Analytics',
          active: true,
        },
      ],
      resource: 'ShipmentsAnalytics',
      action: 'manage',
    },
  },
  {
    path: '/delivery-status-analytics',
    name: 'delivery-status-analytics',
    component: () => import('@/views/pages/inside/Analytics/DeliveryStatusAnalytics.vue'),
    meta: {
      logo: 'admin',
      pageTitle: 'Delivery Status Analytics',
      breadcrumb: [
        {
          text: 'Delivery Status Analytics',
          active: true,
        },
      ],
      resource: 'DeliveryStatusAnalytics',
      action: 'manage',
    },
  },
  {
    path: '/users-analytics',
    name: 'users-analytics',
    component: () => import('@/views/pages/inside/Analytics/UsersAnalytics.vue'),
    meta: {
      logo: 'admin',
      pageTitle: 'Users Analytics',
      breadcrumb: [
        {
          text: 'Users Analytics',
          active: true,
        },
      ],
      resource: 'UsersAnalytics',
      action: 'manage',
    },
  },
  {
    path: '/clicks-analytics',
    name: 'clicks-analytics',
    component: () => import('@/views/pages/inside/Analytics/ClicksAnalytics.vue'),
    meta: {
      logo: 'admin',
      pageTitle: 'Clicks Analytics',
      breadcrumb: [
        {
          text: 'Clicks Analytics',
          active: true,
        },
      ],
      resource: 'ClicksAnalytics',
      action: 'manage',
    },
  },
  {
    path: '/location-analytics',
    name: 'location-analytics',
    component: () => import('@/views/pages/inside/Analytics/LocationAnalytics.vue'),
    meta: {
      logo: 'admin',
      pageTitle: 'Location Analytics',
      breadcrumb: [
        {
          text: 'Location Analytics',
          active: true,
        },
      ],
      resource: 'LocationAnalytics',
      action: 'manage',
    },
  },
  {
    path: '/b2bpro/payment-status',
    name: 'b2bpro-payment-status',
    component: () => import('@/views/pages/inside/B2Bpro/PaymentStatusPage.vue'),
    meta: {
      logo: 'b2bpro',
      pageTitle: 'Payment Status',
      breadcrumb: [
        {
          text: 'Payment Status',
          active: true,
        },
      ],
      resource: 'B2BProPaymentStatus',
      action: 'manage',
    },
  },
  {
    path: '/logs/payment-status',
    name: 'payment-status-logs',
    component: () => import('@/views/pages/inside/Logs/PaymentStatusPage.vue'),
    meta: {
      logo: 'b2bpro',
      pageTitle: 'Payment Status Logs',
      breadcrumb: [
        {
          text: 'Payment Status Logs',
          active: true,
        },
      ],
      resource: 'PaymentStatusLogs',
      action: 'manage',
    },
  },

  //= ========== FLEET FORWARDING =========//
  {
    path: '/freight-forwarding/port',
    name: 'freight-forwarding-port',
    component: () => import('@/views/pages/inside/FleetForwarding/Port/PortDetails.vue'),
    meta: {
      logo: 'admin',
      pageTitle: 'Port',
      breadcrumb: [
        {
          text: 'Freight Forwarding',
          active: false,
        },
        {
          text: 'Port',
          active: true,
        },
      ],
      resource: 'Auth',
      action: 'manage',
    },
  },
  {
    path: '/freight-forwarding/create-delivery',
    name: 'freight-forwarding-create-delivery',
    component: () =>
      import('@/views/pages/inside/FleetForwarding/CreateDelivery/CreateDelivery.vue'),
    meta: {
      logo: 'admin',
      pageTitle: 'Create Delivery',
      breadcrumb: [
        {
          text: 'Freight Forwarding',
          active: false,
        },
        {
          text: 'Create Delivery',
          active: true,
        },
      ],
      resource: 'Auth',
      action: 'manage',
    },
  },
  {
    path: '/fleet-forwarding-delivery-proceed-order/:id/:smarttruckid',
    name: 'fleetforwardingdeliveryproceedorder',
    component: () => import('@/views/pages/inside/FleetForwarding/CreateDelivery/ProceedOrder.vue'),
    meta: {
      logo: 'admin',
      // group: 2,
      pageTitle: 'Freight Forwarding Delivery',
      breadcrumb: [
        {
          text: 'Freight Forwarding Delivery',
          active: true,
        },
      ],
      resource: 'Auth',
      action: 'manage',
    },
  },
  {
    path: '/fleet-forwarding-delivery-success',
    name: 'fleetforwardingdeliverysuccess',
    component: () => import('@/views/pages/inside/FleetForwarding/CreateDelivery/OrderSuccess.vue'),
    meta: {
      logo: 'admin',
      pageTitle: 'Create Delivery',
      breadcrumb: [
        {
          text: 'Freight Forwarding',
          active: false,
        },
        {
          text: 'Create Delivery',
          active: true,
        },
      ],
      resource: 'Auth',
      action: 'manage',
    },
  },
  {
    path: '/freight-forwarding/order-tracker',
    name: 'freight-forwarding-order-tracker',
    component: () =>
      import('@/views/pages/inside/FleetForwarding/OrderTracker/OrderTrackerPage.vue'),
    meta: {
      logo: 'admin',
      pageTitle: 'Order Tracker',
      breadcrumb: [
        {
          text: 'Freight Forwarding',
          active: false,
        },
        {
          text: 'Order Tracker',
          active: true,
        },
      ],
      resource: 'Auth',
      action: 'manage',
    },
  },
  {
    path: '/freight-forwarding/order-details/:id',
    name: 'freight-forwarding-order-details',
    component: () => import('@/views/pages/inside/FleetForwarding/OrderTracker/OrderDetails.vue'),
    meta: {
      logo: 'b2bpro',
      pageTitle: 'Order Summary',
      breadcrumb: [
        {
          text: 'Freight Forwarding',
          active: false,
        },
        {
          text: 'Order Tracker',
          active: false,
          to: { name: 'freight-forwarding-order-tracker' },
        },
        {
          text: 'Order Summary',
          active: true,
        },
      ],
      resource: 'OrderTracker',
      action: 'read',
    },
  },
  {
    path: '/freight-forwarding/inquiries',
    name: 'freight-forwarding-inquiries',
    component: () => import('@/views/pages/inside/FleetForwarding/Inquiries/InquiriesPage.vue'),
    meta: {
      logo: 'admin',
      pageTitle: 'Inquiries',
      breadcrumb: [
        {
          text: 'Freight Forwarding',
          active: false,
        },
        {
          text: 'Inquiries',
          active: true,
        },
      ],
      resource: 'Auth',
      action: 'manage',
    },
  },
  {
    path: '/freight-forwarding/payment-status',
    name: 'freight-forwarding-payment-status',
    component: () =>
      import('@/views/pages/inside/FleetForwarding/PaymentStatus/PaymentStatusPage.vue'),
    meta: {
      logo: 'admin',
      pageTitle: 'Payment Status',
      breadcrumb: [
        {
          text: 'Freight Forwarding',
          active: false,
        },
        {
          text: 'Payment Status',
          active: true,
        },
      ],
      resource: 'Auth',
      action: 'manage',
    },
  },

  //= ========== CHARTERED TRUCK =========//
  {
    path: '/chartered-truck/create-delivery',
    name: 'chartered-truck-create-delivery',
    component: () =>
      import('@/views/pages/inside/CharteredTruck/CreateDelivery/CreateDelivery.vue'),
    meta: {
      logo: 'admin',
      pageTitle: 'Create Delivery',
      breadcrumb: [
        {
          text: 'Chartered Truck',
          active: false,
        },
        {
          text: 'Create Delivery',
          active: true,
        },
      ],
      resource: 'Auth',
      action: 'manage',
    },
  },
  {
    path: '/chartered-truck-delivery-proceed-order/:id/:smarttruckid',
    name: 'charteredtruckdeliveryproceedorder',
    component: () => import('@/views/pages/inside/CharteredTruck/OrderTracker/ProceedOrder.vue'),
    meta: {
      pageTitle: 'Chartered Truck Delivery',
      breadcrumb: [
        {
          text: 'Chartered Truck Delivery',
          active: true,
        },
      ],
      resource: 'Auth',
      action: 'manage',
    },
  },
  {
    path: '/chartered-truck-delivery-success',
    name: 'charteredtruckdeliverysuccess',
    component: () => import('@/views/pages/inside/CharteredTruck/CreateDelivery/OrderSuccess.vue'),
    meta: {
      logo: 'admin',
      pageTitle: 'Chartered Truck Delivery',
      breadcrumb: [
        {
          text: 'Chartered Truck Delivery',
          active: true,
        },
      ],
      resource: 'Auth',
      action: 'manage',
    },
  },
  {
    path: '/chartered-truck/order-tracker',
    name: 'chartered-truck-order-tracker',
    component: () =>
      import('@/views/pages/inside/CharteredTruck/OrderTracker/OrderTrackerPage.vue'),
    meta: {
      logo: 'admin',
      pageTitle: 'Order Tracker',
      breadcrumb: [
        {
          text: 'Chartered Truck',
          active: false,
        },
        {
          text: 'Order Tracker',
          active: true,
        },
      ],
      resource: 'Auth',
      action: 'manage',
    },
  },
  {
    path: '/chartered-truck/order-details/:id',
    name: 'chartered-truck-order-details',
    component: () => import('@/views/pages/inside/CharteredTruck/OrderTracker/OrderDetails.vue'),
    meta: {
      logo: 'b2bpro',
      pageTitle: 'Order Summary',
      breadcrumb: [
        {
          text: 'Chartered Truck',
          active: false,
        },
        {
          text: 'Order Tracker',
          active: false,
          to: { name: 'chartered-truck-order-tracker' },
        },
        {
          text: 'Order Summary',
          active: true,
        },
      ],
      resource: 'OrderTracker',
      action: 'read',
    },
  },
  {
    path: '/chartered-truck/inquiries',
    name: 'chartered-truck-inquiries',
    component: () => import('@/views/pages/inside/CharteredTruck/Inquiries/InquiriesPage.vue'),
    meta: {
      logo: 'admin',
      pageTitle: 'Inquiries',
      breadcrumb: [
        {
          text: 'Chartered Truck',
          active: false,
        },
        {
          text: 'Inquiries',
          active: true,
        },
      ],
      resource: 'Auth',
      action: 'manage',
    },
  },
  {
    path: '/chartered-truck/payment-status',
    name: 'chartered-truck-payment-status',
    component: () =>
      import('@/views/pages/inside/CharteredTruck/PaymentStatus/PaymentStatusPage.vue'),
    meta: {
      logo: 'admin',
      pageTitle: 'Payment Status',
      breadcrumb: [
        {
          text: 'Chartered Truck',
          active: false,
        },
        {
          text: 'Payment Status',
          active: true,
        },
      ],
      resource: 'Auth',
      action: 'manage',
    },
  },
  {
    path: "/log-points/:id/:name",
    name: "log-points",
    component: () => import("@/views/pages/inside/LogPoints/LogPointDetails.vue"),
    meta: {
      logo: 'b2bpro',
      pageTitle: 'Log Points History',
      breadcrumb: [
        {
          text: 'B2BPro',
          active: false,
        },
        {
          text: 'Users',
          active: true,
        },
        {
          text: "Log Points",
          active: true,
        },
      ],
      resource: 'B2BProUsers',
      action: 'manage',
    },
  }
]
