import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import trackAndTrace from './track-and-trace'
import singleDelivery from './single-delivery'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    trackAndTrace,
    singleDelivery,
  },
  strict: process.env.DEV,
})
